var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "merchantadd-container position-relative" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            attrs: {
              "label-width": "120px",
              model: _vm.merchantList,
              inline: true,
              "hide-required-asterisk": true,
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "供货单号",
                              prop: "supplier_order_no",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.merchantList.supplier_order_no)
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "供货商名称", prop: "title" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.merchantList.supplier.title) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系电话", prop: "mobile" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.merchantList.supplier.mobile) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "业务员", prop: "storeType" } },
                          [
                            _vm.merchantList.supplier &&
                            _vm.merchantList.supplier.buyer
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.supplier.buyer.fullname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "档口位置", prop: "stall" } },
                          [
                            _vm.merchantList.supplier
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.merchantList.supplier.stall) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "供货单金额",
                              prop: "product_amount",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      _vm.tool.format_money(
                                        _vm.merchantList.product_amount
                                      )
                                    )
                                  ) +
                                  "元 "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "报损扣款金额" } },
                          [
                            _vm.merchantList.liquidation_status == 0
                              ? _c("div", [_vm._v("—元")])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.tool.format_money(
                                          _vm.merchantList.damage_amount
                                        )
                                      ) +
                                      "元 "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "结算金额",
                              prop: "liquidation_amount",
                            },
                          },
                          [
                            _vm.merchantList.liquidation_status == 0
                              ? _c("div", [_vm._v("—元")])
                              : _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$empty.empty(
                                          _vm.tool.format_money(
                                            _vm.merchantList.liquidation_amount
                                          )
                                        )
                                      ) +
                                      "元 "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.serviceFeeLabel,
                              prop: "service_fee_amount",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  `${
                                    _vm.merchantList.service_fee_amount || 0
                                  }元`
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平台服务费",
                              prop: "supplier_order_no",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${
                                      _vm.merchantList.platform_fee_amount || 0
                                    }元`
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "第三方服务费",
                              prop: "service_charges_fee_real",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${
                                      _vm.merchantList
                                        .service_charges_fee_real || 0
                                    }元`
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平台销售服务费",
                              prop: "service_sell_fee",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${
                                      _vm.merchantList.service_sell_fee || 0
                                    }元`
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "auto-table-flex mb-30" },
                  [
                    _c(
                      "el-collapse",
                      {
                        attrs: { accordion: "" },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          { attrs: { name: "1" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex j-sb",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c("div", { staticClass: "font-weight" }, [
                                    _vm._v("供货单商品列表"),
                                  ]),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "mr40 font-weight" },
                                      [
                                        _vm._v(" 供货总数量："),
                                        _c(
                                          "span",
                                          { staticClass: "main-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  _vm.merchantList
                                                    .product_number
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "font-weight" }, [
                                      _vm._v(" 供货总金额："),
                                      _c(
                                        "span",
                                        { staticClass: "main-color" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$empty.empty(
                                                _vm.tool.format_money(
                                                  _vm.merchantList
                                                    .product_amount
                                                )
                                              )
                                            ) + "元"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                attrs: {
                                  data: _vm.goodsData,
                                  border: true,
                                  "show-header": false,
                                  "header-cell-style": {
                                    color: "#333333",
                                    background: "#EFF6FF",
                                  },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    width: "80",
                                    align: "center",
                                    label: "序号",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "goodsName",
                                    align: "center",
                                    label: "商品主图",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("img", {
                                                  staticClass: "kv pointer",
                                                  attrs: {
                                                    src: scope.row.product.kv,
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.getGlass(
                                                        scope.row.product.kv
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "id_card",
                                    align: "center",
                                    label: "名称",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  scope.row.product.title
                                                )
                                              ) +
                                                " (" +
                                                _vm._s(
                                                  _vm.$empty.empty(
                                                    scope.row.product.sku_code
                                                  )
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "等级",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "等级：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .grade
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "weight",
                                    align: "center",
                                    label: "净重",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "净重：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .weight
                                                        )
                                                      ) +
                                                      "斤"
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "gross_weight",
                                    align: "center",
                                    label: "毛重",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "毛重：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .gross_weight
                                                        )
                                                      ) +
                                                      "斤"
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "供货数量",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "供货数量：" +
                                                _vm._s(
                                                  _vm.$empty.empty(
                                                    scope.row.product_number
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "供货金额",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "供货金额：" +
                                                _vm._s(
                                                  _vm.$empty.empty(
                                                    _vm.tool.format_money(
                                                      scope.row.product_amount
                                                    )
                                                  )
                                                ) +
                                                "元"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-collapse-item",
                          { attrs: { name: "2" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex j-sb",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c("div", { staticClass: "font-weight" }, [
                                    _vm._v("报损商品列表"),
                                  ]),
                                  _c("div", { staticClass: "d-flex" }, [
                                    _vm.merchantList.liquidation_status == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "font-weight" },
                                          [
                                            _vm._v(" 报损总金额："),
                                            _c(
                                              "span",
                                              { staticClass: "main-color" },
                                              [_vm._v("—元")]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "font-weight" },
                                          [
                                            _vm._v(" 报损总金额："),
                                            _c(
                                              "span",
                                              { staticClass: "main-color" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.tool.format_money(
                                                        _vm.merchantList
                                                          .damage_amount
                                                      )
                                                    ) +
                                                    "元"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading",
                                  },
                                ],
                                attrs: {
                                  data: _vm.breakData,
                                  border: true,
                                  "show-header": false,
                                  "header-cell-style": {
                                    color: "#333333",
                                    background: "#EFF6FF",
                                  },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    width: "80",
                                    align: "center",
                                    label: "序号",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "goodsName",
                                    align: "center",
                                    label: "商品主图",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("img", {
                                                  staticClass: "kv pointer",
                                                  attrs: {
                                                    src: scope.row.product.kv,
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.getGlass(
                                                        scope.row.product.kv
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "id_card",
                                    align: "center",
                                    label: "名称",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  scope.row.product.title
                                                )
                                              ) +
                                                " (" +
                                                _vm._s(
                                                  _vm.$empty.empty(
                                                    scope.row.product.sku_code
                                                  )
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "等级",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "等级：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .grade
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "weight",
                                    align: "center",
                                    label: "净重",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "净重：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .weight
                                                        )
                                                      ) +
                                                      "斤"
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "gross_weight",
                                    align: "center",
                                    label: "毛重",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.product
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    "毛重：" +
                                                      _vm._s(
                                                        _vm.$empty.empty(
                                                          scope.row.product
                                                            .gross_weight
                                                        )
                                                      ) +
                                                      "斤"
                                                  ),
                                                ]),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "报损金额",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "报损金额：" +
                                                _vm._s(
                                                  _vm.tool.format_money(
                                                    scope.row.refund_amt
                                                  )
                                                ) +
                                                "元"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "grade",
                                    align: "center",
                                    label: "报损原因",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "报损原因：" +
                                                _vm._s(
                                                  _vm.$empty.empty(
                                                    scope.row.desc
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "fy1",
        staticStyle: {
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
        },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }